import React, { useState, useRef, useEffect } from 'react';

export const TextContainer = ({ children }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        const textElement = textRef.current;
        if (textElement.scrollHeight > textElement.clientHeight) {
            setIsOverflowed(true);
        }
    }, []);

    const handleShowMore = () => {
        setIsExpanded(true);
    };

    return (
        <div
            className={`text-container ${isExpanded ? 'expanded' : ''}`}
            ref={textRef}
        >
            <p className="text-content">{children}</p>
            {isOverflowed && !isExpanded && (
                <a href="#" className="show-more-link" onClick={handleShowMore}>
                    Show More
                </a>
            )}
        </div>
    );
};