// src/WordSearch.js

import React, { useMemo } from "react";

const generateRandomLetter = () => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
};

const generateGrid = (cols,rows) => {
  return Array.from({ length: cols }, () =>
    Array.from({ length: rows }, () => generateRandomLetter())
  );
};

export const WordSearchGrid = ({ cols, rows }) => {
  // Memoize the grid based on the to prevent unnecessary recalculations
  const grid = useMemo(() => generateGrid(cols,rows), [cols,rows]);

  return (
    <div className="word-search-container">
      <div
        className="word-search-grid"
        style={{
          gridTemplateColumns: `repeat(${cols}, 1fr)`,
          gridTemplateRows: `repeat(${rows}, 1fr)`,
        }}
      >
        {grid.map((row, rowIndex) =>
          row.map((letter, colIndex) => (
            <div key={`${rowIndex}-${colIndex}`} className="word-search-cell">
              {letter}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
