import { browserLocalPersistence } from "firebase/auth"
import { NavBar } from './NavBar';
import { BottomNav } from "./BottomNav";

export const Layout = ({children, layoutName, noNav, title}) => {

  switch( layoutName )
  {
    default:
      return (
        <>
          <div className="App overscroll-contain container mx-auto px-4">
            {noNav ? null : <NavBar title={title} />}
            <main className={ "container mx-auto px-4 main-container mb-16"}>
              {children}
            </main>
          <BottomNav />
          </div>
        </>
      );
    break;
  }
}