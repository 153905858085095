import { useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  FileInput,
  Label,
  Radio,
  RangeSlider,
  Textarea,
  TextInput,
  ToggleSwitch,
  Avatar, Dropdown, Card
} from "flowbite-react";

import { useNavigate } from 'react-router-dom';

export const NavBar = ({title}) => {

  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  }


  return (
    <>
      <header class="h-16 bg-main sticky top-0 z-50 top-0 left-0 w-full">
        <div class="container mx-auto px-4">
          <div class="flex justify-between items-center py-4">
            <h1 class="text-2xl font-bold" onClick={() =>{ goHome()}}>{title ? title:"Vocab Vault"}</h1>
          </div>
        </div>
      </header>
    </>
  );
}