import React, { useState, useEffect } from 'react';
import { useFirestore } from '../FirestoreContext';
import { WordCard } from "./WordCard";
import {TextContainer} from "./TextContainer";

// Debounce utility function
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const WordSearch = () => {
  const { searchResults, searchWords } = useFirestore();
  const [searchTerm, setSearchTerm] = useState('');

  // Debounced search term
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    searchWords(debouncedSearchTerm);
  }, [debouncedSearchTerm, searchWords]);

  return (
    <div>
      <h2>Search Words</h2>
      <input
        type="text"
        placeholder="Search for a word"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ul>
        {searchResults.map(word => (
<WordCard>
            <div key={word.id} className="main-word">{word.word}</div>
            <div className="word-pronouncination">{word.pronunciation}</div>
            <TextContainer>
              <div className="word-desc">{word.definition}</div>
            </TextContainer>
          </WordCard>
        ))}
      </ul>
    </div>
  );
};
