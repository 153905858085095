export const WordCard = ({
  children,
  title,
  onClickHandler,
  highlighted = false }) => {

  const handleCardClick = (event) => {
    event.preventDefault();

    if (onClickHandler) {
      onClickHandler();
    }
  }

  return (
    <>
      <div class="word-card">
        {children}
      </div>
    </>
  )
}