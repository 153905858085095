import { Layout } from '../components/Layout';

export const Words = () => {

  return (
    <>
    <Layout>
      Lists all the available words
      <p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p>
    </Layout>
    </>
  );
}