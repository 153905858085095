import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { collection, addDoc, getDocs, getDoc, deleteDoc, doc, updateDoc, query, where, limit } from 'firebase/firestore';
import { db, auth} from './firebase.config'; // Make sure this points to your config

// Create context
const FirestoreContext = createContext();

// Actions
const ACTIONS = {
  ADD_WORD: 'ADD_WORD',
  FETCH_WORDS: 'FETCH_WORDS',
  SEARCH_WORDS: 'SEARCH_WORDS',
  DELETE_WORD: 'DELETE_WORD',
  UPDATE_WORD: 'UPDATE_WORD',
};

// Reducer function
const firestoreReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_WORDS:
      return { ...state, words: action.payload };
    case ACTIONS.SEARCH_WORDS:
      return { ...state, searchResults: action.payload };
    case ACTIONS.ADD_WORD:
      return { ...state, words: [...state.words, action.payload] };
    case ACTIONS.DELETE_WORD:
      return {
        ...state,
        words: state.words.filter(word => word.id !== action.payload),
      };
    case ACTIONS.UPDATE_WORD:
      return {
        ...state,
        words: state.words.map(word =>
          word.id === action.payload.id ? action.payload : word
        ),
      };
    default:
      return state;
  }
};

// Initial State
const initialState = {
  words: [],
  searchResults: [],
};

// FirestoreProvider to provide state and dispatch
export const FirestoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(firestoreReducer, initialState);

// Fetch words only for the logged-in user
const fetchWords = useCallback(async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const q = query(
    collection(db, 'words'),
    where('userId', '==', user.uid) // Filter words by userId
  );
  
  const wordSnapshot = await getDocs(q);
  const wordsList = wordSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  dispatch({ type: ACTIONS.FETCH_WORDS, payload: wordsList });
}, []);

const searchWords = useCallback(async (searchTerm) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  if (!searchTerm) {
    dispatch({ type: ACTIONS.SEARCH_WORDS, payload: [] });
    return;
  }

  const q = query(
    collection(db, 'words'),
    where('userId', '==', user.uid), // Ensure search is scoped to the logged-in user
    where('word', '>=', searchTerm),
    where('word', '<=', searchTerm + '\uf8ff'),
    limit(50)
  );

  const querySnapshot = await getDocs(q);
  const searchResults = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  dispatch({ type: ACTIONS.SEARCH_WORDS, payload: searchResults });
}, []);




  
// Add a word for the logged-in user
const addWord = async (newWord) => {
  const user = auth.currentUser; // Get the current logged-in user
  if (!user) throw new Error('User not authenticated');

  const wordWithUserId = { ...newWord, userId: user.uid, dateAdded: new Date().toISOString() };
  const docRef = await addDoc(collection(db, 'words'), wordWithUserId);
  dispatch({
    type: ACTIONS.ADD_WORD,
    payload: { ...wordWithUserId, id: docRef.id },
  });
  };

  const deleteWord = async (id) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');
  
    const wordRef = doc(db, 'words', id);
    const wordDoc = await getDoc(wordRef);
  
    if (wordDoc.exists() && wordDoc.data().userId === user.uid) {
      await deleteDoc(wordRef);
      dispatch({ type: ACTIONS.DELETE_WORD, payload: id });
    } else {
      console.error('Cannot delete a word that does not belong to the user');
    }
  }

  const updateWord = async (id, updatedWord) => {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');
  
    const wordRef = doc(db, 'words', id);
    const wordDoc = await getDoc(wordRef);
  
    if (wordDoc.exists() && wordDoc.data().userId === user.uid) {
      await updateDoc(wordRef, updatedWord);
      dispatch({
        type: ACTIONS.UPDATE_WORD,
        payload: { id, ...updatedWord },
      });
    } else {
      console.error('Cannot update a word that does not belong to the user');
    }
  }

  return (
    <FirestoreContext.Provider
      value={{
        words: state.words,
        searchResults: state.searchResults,
        fetchWords,
        searchWords,
        addWord,
        deleteWord,
        updateWord,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};

// Custom hook to use Firestore context
export const useFirestore = () => useContext(FirestoreContext);
