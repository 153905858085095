import React, { useEffect, useState } from 'react';
import { useFirestore } from '../FirestoreContext';

export const WordList = () => {
  const { words, fetchWords, addWord, deleteWord, updateWord } = useFirestore();
  const [newWord, setNewWord] = useState({ word: '', definition: '', pronunciation: '', dateAdded: '' });

  // Fetch words when the component mounts
  useEffect(() => {
    fetchWords();
  }, [fetchWords]); // Now we can safely include fetchWords as a dependency

  const handleAddWord = () => {
    addWord({ ...newWord, dateAdded: new Date().toISOString() });
    setNewWord({ word: '', definition: '', pronunciation: '', dateAdded: '' });
  };

  const handleDeleteWord = (id) => {
    deleteWord(id);
  };

  const handleUpdateWord = (id) => {
    updateWord(id, { ...newWord, dateAdded: new Date().toISOString() });
    setNewWord({ word: '', definition: '', pronunciation: '', dateAdded: '' });
  };

  return (
    <div>
      <h2>Word List</h2>
      <input
        type="text"
        placeholder="Word"
        value={newWord.word}
        onChange={(e) => setNewWord({ ...newWord, word: e.target.value })}
      />
      <input
        type="text"
        placeholder="Definition"
        value={newWord.definition}
        onChange={(e) => setNewWord({ ...newWord, definition: e.target.value })}
      />
      <input
        type="text"
        placeholder="Pronunciation"
        value={newWord.pronunciation}
        onChange={(e) => setNewWord({ ...newWord, pronunciation: e.target.value })}
      />
      <button onClick={handleAddWord}>Add Word</button>

      <ul>
        {words.map(word => (
          <li key={word.id}>
            <strong>{word.word}</strong>: {word.definition} ({word.pronunciation})
            <button onClick={() => handleDeleteWord(word.id)}>Delete</button>
            <button onClick={() => handleUpdateWord(word.id)}>Update</button>
          </li>
        ))}
      </ul>
    </div>
  );
};