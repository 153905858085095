import logo from './logo.svg';
import './App.css';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Layout } from './components/Layout'
import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { WordCard } from "./components/WordCard";
import {TextContainer} from "./components/TextContainer";
import {WordSearch} from './components/WordSearch';
import { auth } from './firebase.config';

function App() {
  const user = auth.currentUser;
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    
  },[])

  const handleSubmit = () => {
    const inputs = {
      gameName: 'rubix-race'
    }
    //writeDoc(inputs, "game-plays").then(console.log);
  }

  function onCloseModal() {
    setOpenModal(false);
    setEmail('');
  }


  return (<>
    <Layout>
    <p>Welcome, {user.displayName}!</p>
    <WordSearch />
      
   </Layout>
   </>

  );
}

export default App;
