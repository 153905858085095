import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout';
import { useFirestore } from '../FirestoreContext';
import { useContext, useMemo } from 'react';


export const AddWord = () => {

  const { words, fetchWords, addWord, deleteWord, updateWord } = useFirestore();
  const [newWord, setNewWord] = useState({ word: '', definition: '', pronunciation: '', dateAdded: '' });

  // Fetch words when the component mounts
  useEffect(() => {
    fetchWords();
  }, [fetchWords]); // Now we can safely include fetchWords as a dependency

  const handleAddWord = () => {
    addWord({ ...newWord, dateAdded: new Date().toISOString() });
    setNewWord({ word: '', definition: '', pronunciation: '', dateAdded: '' });
  };


  return (
    <>
    <Layout>
    <label>Word</label>
    <input
        type="text"
        placeholder="Word"
        value={newWord.word}
        onChange={(e) => setNewWord({ ...newWord, word: e.target.value })}
      />
      <label>Definition</label>
      <input
        type="text"
        placeholder="Definition"
        value={newWord.definition}
        onChange={(e) => setNewWord({ ...newWord, definition: e.target.value })}
      />

      <label>Pronounciation</label>
      <input
        type="text"
        placeholder="Pronunciation"
        value={newWord.pronunciation}
        onChange={(e) => setNewWord({ ...newWord, pronunciation: e.target.value })}
      />
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleAddWord}>Add Word</button>
    </Layout>
    </>
  );
}