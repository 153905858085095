import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import React from 'react';
import { FirestoreProvider } from './FirestoreContext';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

/* ROUTES*/
import { Word } from './routes/Word';
import { Words } from './routes/Words';
import { WordSearch } from './routes/WordSearch';
import { Search } from './routes/Search';
import { AddWord } from './routes/AddWord';
import { WordList } from './routes/WordList';
import { LoginPage } from './routes/LoginPage';
import RequireAuth from './components/RequireAuth';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <FirestoreProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<RequireAuth><App /></RequireAuth>} />
          <Route path="/search" element={<Search />} />
          <Route path="/words" element={<Words />} />
          <Route path="/word-search" element={<WordSearch />} />
          <Route path="/word" element={<Word />} />
          <Route path="/add-word" element={<RequireAuth><AddWord /></RequireAuth>} />
          <Route path="/test-page" element={<WordList />} />
      </Routes>
    </BrowserRouter>
  </FirestoreProvider>
);