import { Layout } from '../components/Layout'
import { WordSearchGrid } from '../components/WordSearchGrid';
export const WordSearch = () => {

  let cols = 12;
  let rows = 16;

  return (
    <>
    <Layout>
      <WordSearchGrid cols={cols} rows={rows} />
      </Layout>
    </>
  );
}