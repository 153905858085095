import React from 'react';
import { auth, provider } from '../firebase.config';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate('/'); // Redirect to protected page upon successful login
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Welcome, Please Sign In</h2>
      <button onClick={handleGoogleSignIn} style={styles.button}>
        Sign In with Google
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  title: {
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#4285F4',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
  },
};