import { Layout } from '../components/Layout';

export const Search = () => {

  return (
    <>
    <Layout>
      <input />
    </Layout>
    </>
  );
}