import { Layout } from '../components/Layout';

export const Word = () => {

  return (
    <>
    <Layout>
      A single word
      <p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p><p>matt drumm</p>
    </Layout>
    </>
  );
}